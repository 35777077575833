import React from "react"
import { Link } from "gatsby"

const Menu = () => (
    <nav className="flex flex-wrap mt-5 justify-center">
        <Link to="/" activeClassName="text-primary-link" className="tracking-wider text-primary text-xl m-1">About</Link>
        <Link to="/community" activeClassName="text-primary-link" className="tracking-wider text-primary text-xl m-1 mx-4">Community</Link>
        {/* <Link to="/" className="tracking-wider text-gray-500 text-xl m-1">Blog</Link> */}
    </nav>
)

export default Menu